import { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Container, Fab, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { Mic, MicOff, Delete, Save, SwitchLeftTwoTone } from '@mui/icons-material/';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import './Speech.css';
import { useRegion } from '../../context/Region';
import { useToken } from '../../context/Token';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useMessageUpdate } from '../../components/Message';
import { useMessageUpdateType } from '../../components/MessageType';

function Speech() {

  const INF = 100000000;
  const SPEECH_KEY = useToken();
  const SPEECH_REGION = useRegion();
  const navigate = useNavigate();
  const [isListening, setIsListening] = useState(false);
  const [myTranscript, setMyTranscript] = useState("");
  const [myHypothesisTranscript, setMyHypothesisTranscript] = useState("");
  const [recognizingHypothesisTranscript, setRecognizingHypothesisTranscript] = useState("");
  const [recognizingTranscript, setRecTranscript] = useState("");
  const [transLang, setTransLang] = useState("ar");
  const [originLang, setOriginLang] = useState("en-US");
  const [status, setStatus] = useState("");
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);
  const [showRecognizingHypothesisTranscript, setShowRecognizingHypothesisTranscript] = useState(true);
  const [showMyHypothesisTranscript, setShowMyHypothesisTranscript] = useState(true);
  const [forceRestart, setForceRestart] = useState(true);
  const [restartTime, setRestartTime] = useState(5);
  const [intervalID, setIntervalID] = useState(null);

  const langMapping = {
    'en': 'en-US',
    'ar': 'ar-LB'
  };
  const setMessage = useMessageUpdate();
  const setMessageType = useMessageUpdateType();

  const clearToken = () => {
    try {
      const cookie = new Cookies();
      const hash = cookie.get('ID');
      cookie.remove('ID');
      cookie.remove(hash);
      setTimeout(() => {
        window.location.reload();
      }, 200);
      window.location = "/#/login";
    } catch (err) {
      setMessage("An error has occurred. When trying to logout");
      setMessageType("error");
    }
  }

  const processRecognizedTranscript = (sender, event) => {
    // 0 NoMatch
    // 8 TranslatedSpeech
    //EndOfDictation
    const result = event.result;
    if (result.reason === sdk.ResultReason.TranslatedSpeech) {
      const transcript = result.text;
      const translation = result.translations.get(transLang);
      setRecTranscript((current) => current + " " + translation);
      setMyTranscript((current) => current + " " + transcript);
      setMyHypothesisTranscript("");
      setRecognizingHypothesisTranscript("");
      setStatus("listening...");
    } else if (result.reason === sdk.ResultReason.NoMatch) {
      setStatus("we did not catch it, can you repeat");
    }
  };

  const processRecognizingTranscript = (sender, event) => {
    // 7 TranslatingSpeech
    const result = event.result;
    if (result.reason === sdk.ResultReason.TranslatingSpeech) {
      const transcript = result.text;
      const translation = result.translations.get(transLang);
      setMyHypothesisTranscript(transcript);
      setRecognizingHypothesisTranscript(translation);
      // setStatus("\"" + transcript + "\"");

    }
  }

  const processSessionStarted = (sender, event) => {
    console.log('start listening...');
    setStatus("listening...");
  }

  const processSessionStoped = (sender, event) => {
    console.log('stop listening');
    setStatus("stop listening");
  }


  const processSpeechStartDetected = (sender, event) => {
    console.log("detecting");
    setStatus("detecting");
  }

  const processSpeechEndDetected = (sender, event) => {
    console.log("detected");
    setStatus("detected");
  }

  const processCanceled = (sender, event) => {
    if (event.privErrorCode === 4) {
      clearToken();
    }
  }

  const initEventsCallback = (r) => {
    r.current.recognized = (s, e) => processRecognizedTranscript(s, e);
    r.current.recognizing = (s, e) => processRecognizingTranscript(s, e);
    r.current.sessionStarted = (s, e) => processSessionStarted(s, e);
    r.current.sessionStopped = (s, e) => processSessionStoped(s, e);
    r.current.speechStartDetected = (s, e) => processSpeechStartDetected(s, e);
    r.current.speechEndDetected = (s, e) => processSpeechEndDetected(s, e);
    r.current.canceled = (s, e) => processCanceled(s, e);
  }

  const restart = async () => {
    await recognizer.current.stopContinuousRecognitionAsync();
    await recognizer.current.startContinuousRecognitionAsync();
  }

  useEffect(() => {
    document.getElementById("filled-original-static").scrollTop = INF;
    document.getElementById("filled-translated-static").scrollTop = INF;
  }, [myTranscript, recognizingTranscript]);

  useEffect(() => {

    if (SPEECH_KEY === "" || SPEECH_KEY === undefined || SPEECH_KEY === null ||
      SPEECH_REGION === "" || SPEECH_REGION === undefined || SPEECH_REGION === null)
      navigate("/login");
    else {
      if (recognizer.current !== null) recognizer.current.stopContinuousRecognitionAsync();

      if (SPEECH_KEY === "" || SPEECH_KEY === undefined || SPEECH_KEY === null || SPEECH_REGION === "" || SPEECH_REGION === undefined || SPEECH_REGION === null) {
        navigate("/login");
      } else {

        speechConfig.current = sdk.SpeechTranslationConfig.fromAuthorizationToken(
          SPEECH_KEY,
          SPEECH_REGION
        );
        speechConfig.current.speechRecognitionLanguage = originLang;
        speechConfig.current.addTargetLanguage(transLang);
        audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();

        recognizer.current = new sdk.TranslationRecognizer(
          speechConfig.current,
          audioConfig.current
        );

        initEventsCallback(recognizer);
        if (isListening) {
          recognizer.current.startContinuousRecognitionAsync();
          if (forceRestart) {
            clearInterval(intervalID);
            setIntervalID(setInterval(() => {
              restart();
            }, restartTime * 1000));
          }
        }
      }
    }

  }, [forceRestart, restartTime, transLang, originLang, SPEECH_KEY, SPEECH_REGION]);

  const startListening = async () => {
    if (!isListening && recognizer.current !== null) {
      await recognizer.current.startContinuousRecognitionAsync();
      setIsListening(true);
      if (forceRestart) {
        clearInterval(intervalID);
        setIntervalID(setInterval(() => {
          restart();
        }, restartTime * 1000));
      }
    }
  };

  const stopListening = async () => {
    if (isListening && recognizer.current !== null) {
      clearInterval(intervalID);
      await recognizer.current.stopContinuousRecognitionAsync();
      setIsListening(false);
      initEventsCallback(recognizer);
    }
  };

  const handleTransLangChange = (event) => {
    setTransLang(event.target.value);
  };

  const handleOriginLangChange = (event) => {
    setOriginLang(event.target.value);
  };

  const removeText = () => {
    setMyTranscript("");
    setRecTranscript("");
  };

  const downloadText = (event, prefix, value) => {
    const link = document.createElement("a");
    const content = value;
    const file = new Blob([content], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = prefix + "_lucidoo.txt";
    link.click();
    URL.revokeObjectURL(link.href);
  }

  const reverseLang = () => {
    setOriginLang(langMapping[transLang]);
    setTransLang(originLang.replace(/-.*$/g, ''));
  }


  return (
    <Grid container component="main" sx={{ maxWidth: '1400px !important' }} spacing={2}>
      {/* <Grid item xs={false} sm={2}>
                <Container maxWidth="sm"></Container>
            </Grid> */}
      <Grid item container xs={12} sm={12} sx={{ height: '100vh', width: '100%' }}>
        <Stack sx={{ height: '100%', width: '100%' }}>
          <Container sx={{ maxWidth: '1400px !important', width: '100%', }}>
            <Box sx={{
              width: '100%',
              flexWrap: "wrap",
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: 'flex-start',
              justifyContent: 'space-around',
            }} >
              <Container sx={{ width: { md: '46%', sm: '100%' }, mt: 2 }}>
                <Fab onClick={(e) => downloadText(e, 'origin', myTranscript)}
                  sx={{ mt: 2 }} size="small" color="primary">
                  <Save />
                </Fab>
                <FormControl variant="filled" sx={{ m: 1 }}>
                  <InputLabel id="originLang-simple-select-autowidth-label">Languages</InputLabel>
                  <Select
                    labelId="originLang-simple-select-autowidth-label"
                    id="originLang-simple-select-autowidth"
                    value={originLang}
                    onChange={handleOriginLangChange}
                    autoWidth
                    label="Languages"
                  >
                    <MenuItem value="en-US">English</MenuItem>
                    <MenuItem value="ar-LB">Arabic</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel onChange={() => setShowMyHypothesisTranscript(!showMyHypothesisTranscript)} control={<Checkbox defaultChecked />} label="Show guessing" />
                <TextField
                  id="filled-original-static"
                  label="Original"
                  multiline
                  rows={12}
                  value={myTranscript}
                  variant="filled"
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {(showMyHypothesisTranscript) && (<TextField
                  id="filled-hypothesis-original-static"
                  value={myHypothesisTranscript}
                  variant="filled"
                  fullWidth
                  multiline
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />)
                }
              </Container>
              <Fab onClick={(e) => reverseLang()}
                size="small" color="primary" sx={{ mt: 2 }}>
                <SwitchLeftTwoTone />
              </Fab>
              <Container sx={{ width: { md: '46%', sm: '100%' }, mt: 2 }}>
                <Fab onClick={(e) => downloadText(e, transLang, recognizingTranscript)}
                  sx={{ mt: 2 }} size="small" color="primary">
                  <Save />
                </Fab>
                <FormControl variant="filled" sx={{ m: 1 }}>
                  <InputLabel id="transLang-simple-select-autowidth-label">Languages</InputLabel>
                  <Select
                    labelId="v-simple-select-autowidth-label"
                    id="transLang-simple-select-autowidth"
                    value={transLang}
                    onChange={handleTransLangChange}
                    autoWidth
                    label="Languages"
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="ar">Arabic</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel onChange={() => setShowRecognizingHypothesisTranscript(!showRecognizingHypothesisTranscript)} control={<Checkbox defaultChecked />} label="Show guessing" />
                <TextField
                  id="filled-translated-static"
                  label="Translated"
                  multiline
                  value={recognizingTranscript}
                  rows={12}
                  variant="filled"
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {(showRecognizingHypothesisTranscript) && (
                  <TextField
                    id="filled-hypothesis-translated-static"
                    value={recognizingHypothesisTranscript}
                    variant="filled"
                    fullWidth
                    multiline
                    disabled
                    InputProps={{
                      readOnly: true,
                    }}
                  />)
                }
              </Container>
            </Box>
          </Container>
          <Container sx={{ width: '100%', py: 6, }}>
            <Box sx={{
              height: '100%',
              width: '100%',
              flexWrap: "wrap",
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
            }}>
              <Container sx={{
                flexGrow: 1,
                textAlign: 'center',
                marginRight: '200px'
              }}>
                <FormControlLabel onChange={() => { clearInterval(intervalID); setForceRestart(!forceRestart); }} control={<Checkbox defaultChecked />} label="Force Restart after:" />
                <Select
                  labelId="restartTime-simple-select-autowidth-label"
                  id="restartTime-simple-select-autowidth"
                  value={`${restartTime}`}
                  onChange={(e) => setRestartTime(e.target.value)}
                  autoWidth
                  label="Languages"
                >
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="15">15</MenuItem>
                </Select>
                <Fab onClick={() => removeText()}
                  sx={{ m: 0.5 }} variant="extended" size="large" color="warning" aria-label="delete">
                  <Delete />
                </Fab>
                <Fab onClick={() => startListening()}
                  sx={{ m: 0.5 }} variant="extended" size="large" color="primary" disabled={isListening}>
                  <Mic />
                </Fab>
                {isListening && (<Fab onClick={() => stopListening()}
                  sx={{ m: 0.5 }} variant="extended" size="large" color="error" >
                  <MicOff />
                </Fab>)}
              </Container>
              <Container sx={{
                flexGrow: 1,
                textAlign: 'center'
              }}>
                <div>
                  <p> {status}</p>
                </div>
              </Container>
            </Box>
          </Container>
        </Stack>
      </Grid>
      {/* <Grid item xs={false} sm={2}>
                <Container maxWidth="sm"></Container>
            </Grid> */}
    </Grid >
  );
}

export default Speech;