import Cookie from 'universal-cookie';
import React, { useContext, useState } from 'react'

const tokenContext = React.createContext()
const tokenUpdateContext = React.createContext()

export function useToken() {
    return useContext(tokenContext)
}

export function useTokenUpdate() {
    return useContext(tokenUpdateContext)
}

export function TokenProvider({ children }) {

    const cookie = new Cookie();
    const [token, setToken] = useState(() => {
        try {
            const hash = cookie.get('ID');
            const speechToken = atob(cookie.get(hash));
            if (speechToken !== undefined) {
                const idx = speechToken.indexOf(':');
                return speechToken.slice(idx + 1);
            }
            return "";
        } catch (e) {
            return "";
        }
    });

    return (
        <tokenContext.Provider value={token}>
            <tokenUpdateContext.Provider value={setToken}>
                {children}
            </tokenUpdateContext.Provider>
        </tokenContext.Provider>
    )
}