import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEnv } from '../../env/Env';
import axios from 'axios';
import { useMessageUpdate } from '../../components/Message'
import { useMessageUpdateType } from '../../components/MessageType'
import { useUsernameUpdate } from '../../context/Username'
import { useTokenUpdate } from '../../context/Token'
import { useRegionUpdate } from '../../context/Region';
import { useNavigate } from 'react-router-dom';
import Cookie from 'universal-cookie';

export default function Login() {
    const env = useEnv();
    const setMessage = useMessageUpdate();
    const setMessageType = useMessageUpdateType();

    const setUsername = useUsernameUpdate();
    const setToken = useTokenUpdate();
    const setRegion = useRegionUpdate();
    const navigate = useNavigate();

    const generateToken = (token, region, username) => {
        try {
            const cookie = new Cookie();
            const hash = btoa(username);
            cookie.set(hash, btoa(region + ':' + token));
            cookie.set('ID', hash);
            navigate("/speech");
        } catch (err) {
            setMessage("An error has occurred. When trying to save your credentials.");
            setMessageType("error");
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        await axios.post(
            env["BACKEND_URL"] + "/api/login",
            {
                "username": formData.get('username'),
                "password": formData.get('password'),
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                validateStatus: function (status) {
                    return status < 402;
                }
            }
        )
            .then(r => {
                const { data } = r;
                setMessage(data['message']);
                setMessageType(data['messageType']);
                setUsername(data['username']);
                setToken(data['token']);
                setRegion(data['region']);
                generateToken(data['token'], data['region'], data['username']);
            })
            .catch(e => {
                setMessage("An error has occurred. Please try again, or feel free to reach out to our support team for assistance.");
                setMessageType('error');
            });

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container>
    );
}
